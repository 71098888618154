.intro {
  columns: 3;
  column-gap: 2em;
  font-family: Roboto, sans-serif;
  font-weight: 300;
  color: var(--page-text-color);/* #3f3f3f;*/
  font-size: 0.9em;
  padding-bottom: 1em;
  text-align: justify;

  @media only screen and (max-width: 650px) {
    columns: 1;
  }

  column {
    display: block;
    break-inside: avoid-column;
  }

  .icons {
    font-size: x-large;
    font-size: clamp(14px, 1.8vw, 24px);
  }

  .icons .icon {
    font-size: x-large;
    font-size: calc(clamp(14px, 1.8vw, 25)*1.5);
    fill: #aa6239;
  }

  .welcome {
    font-size: xxx-large;
    font-size: clamp(24px, 5vw, 58px);
    font-weight: 700;
    line-height: 1em;
    text-align: left;
  }

  .separator {
    width: 49%;
    border-bottom: 0.2em solid #aa6239;
    margin-bottom: 1em;
  }

  .lprp {
    font-family: Allan, Sans-serif;
    font-weight: 400;
    font-size: large;
    font-size: clamp(14px, 2vw, 26px);
  }

  .intro-img {
    text-align: center;
    margin-bottom: 15px;
  }

  img {
    transition: transform 0.4s ease-in-out 0s;
    box-shadow: 0px 0px 10px 0px color-mod(var(--page-text-color) alpha(0.5));
    margin: 0.3em;

    height: auto;

    &:hover {
      overflow: hidden;
      transform: scale(1.05);
    }
  }
}
