.site-footer {
    background-color: var(--title-background);
    border-top: 1px solid var(--title-background-line);
    color: var(--title-background-fg-color);
    padding: 10px 0;
    text-align: center;
    font-size: smaller;
  
    p {
      margin: 0;
    }
  }
  