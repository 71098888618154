

.tag-item:not(:first-of-type):before {
    content: '|';
    color: var(--title-background);
    margin: 0 0.5em;
  }
  
  .meta-tags {
  
    .tag-list {
      padding-left: 0.3em;
    }
  
    .tag-item {
      text-transform: uppercase;
      color: inherit;
    }
  
  }
  
  
  .related_posts_header {
    width: 100%;
    line-height: 3em;
    margin: 0.4em 0;
    display: flex;
    font-size: large;
    font-size: clamp(12px, 3vw, 22px);
    align-items: center;
    font-weight: 400;
  
    color: var(--page-text-color);
  
    .prev,
    .next {
      height: 100%;
      flex: 1 1 0px;
      padding: 0 1em;
      background-color: var(--page-text-color-smooth);
      color: var(--page-background);
      vertical-align: middle;
      text-align: center;
      white-space: nowrap;
    }
  
    .title {
      flex: 1 1 60%;
      text-align: center;
      font-size: x-large;
      font-size: clamp(20px, 5vw, 40px);
    }
  
  }
  
  