.wrapper {
  max-width: -webkit-calc(1200px - (30px * 2));
  max-width: calc(1200px - (30px * 2));

  font-family: Roboto, sans-serif;
  font-weight: 300;
}

.post-content h1 {
  font-size: 2em;
}

.post-content h2 {
  font-size: 1.7em;
}

.post-content h3 {
  font-size: 1.4em;
}

.post-content h4 {
  font-size: 1.2em;
}

.icon {
  width: 1em;
  fill: var(--page-text-color);
}

.icon {
  display: inline-block;
  // font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -.125em;
}


div.fa-svg-generate {
  display: none;
}


.highlighter-rouge .highlight, .highlight, pre, code {
  background-color: var(----page-background-hover);
}

table, table td {
  color: var(--page-text-color);
  background-color: var(--page-background);
}

table th {
  color: var(--page-text-color);
  background-color: var(--page-background-hover);
}

code {
  border: none;
}

math {
  margin-bottom: 15px; // Replicate same as pargraph for ascimath
}