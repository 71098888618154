nav {
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}


.pagination {
  display: flex;
  margin-top: 1em;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  font-size: 14px;
  font-weight: 600;
  flex-wrap: wrap;
  justify-content: center;
}
 
.page-link {
  position: relative;
  display: block;
  padding: 0.55rem .8rem;
  margin-left: -1px;
  line-height: 1.25;
  color: var(--page-text-color);
  background-color: var(--page-background);
  border: 1px solid var(--page-text-color);

  &:hover {
    z-index: 2;
    text-decoration: none;
    background-color: var(--page-background-hover);
  }

  &:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(3, 168, 124, 0.25);
  }

  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
}

.page-item {
  &:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  &:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  &.active .page-link {
    z-index: 1;
    color: var(--page-background);
    background-color: var(--page-background-hover);
    border-color: var(--page-text-color);
  }

  &.disabled .page-link {
    color: #707070;
    pointer-events: none;
    cursor: auto;
    background-color: var(--page-background-hover);
    border-color: var(--page-text-color);
  }
}

.pagination-lg {
  .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.15rem;
    line-height: 1.5;
  }

  .page-item {
    &:first-child .page-link {
      border-top-left-radius: 0.3rem;
      border-bottom-left-radius: 0.3rem;
    }

    &:last-child .page-link {
      border-top-right-radius: 0.3rem;
      border-bottom-right-radius: 0.3rem;
    }
  }
}

.pagination-sm {
  .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }

  .page-item {
    &:first-child .page-link {
      border-top-left-radius: 0.2rem;
      border-bottom-left-radius: 0.2rem;
    }

    &:last-child .page-link {
      border-top-right-radius: 0.2rem;
      border-bottom-right-radius: 0.2rem;
    }
  }
}